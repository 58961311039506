import { render } from "./IconBtn.vue?vue&type=template&id=223402d2"
import script from "./IconBtn.ts?vue&type=script&lang=ts"
export * from "./IconBtn.ts?vue&type=script&lang=ts"

import "./icon-btn.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "223402d2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('223402d2', script)) {
    api.reload('223402d2', script)
  }
  
  module.hot.accept("./IconBtn.vue?vue&type=template&id=223402d2", () => {
    api.rerender('223402d2', render)
  })

}

script.__file = "src/components/IconBtn/IconBtn.vue"

export default script
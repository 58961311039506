import { Nullable } from 'core/types';

export function cityIdByPath(path: string): Nullable<number> {
  switch (path) {
    case '/spb': return 1;
    case '/moscow': return 2;
    case '/sochi': return 3;
    case '/test': return 4;
    default: return null;
  }
}

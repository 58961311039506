import { render } from "./RadioGroup.vue?vue&type=template&id=393b90e2"
import script from "./RadioGroup.ts?vue&type=script&lang=ts"
export * from "./RadioGroup.ts?vue&type=script&lang=ts"

import "./radioGroup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "393b90e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('393b90e2', script)) {
    api.reload('393b90e2', script)
  }
  
  module.hot.accept("./RadioGroup.vue?vue&type=template&id=393b90e2", () => {
    api.rerender('393b90e2', render)
  })

}

script.__file = "src/components/RadioGroup/RadioGroup.vue"

export default script
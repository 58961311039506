import { render } from "./PushNotifications.vue?vue&type=template&id=2a14e926"
import script from "./PushNotifications.ts?vue&type=script&lang=ts"
export * from "./PushNotifications.ts?vue&type=script&lang=ts"

import "./pushNotifications.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2a14e926"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2a14e926', script)) {
    api.reload('2a14e926', script)
  }
  
  module.hot.accept("./PushNotifications.vue?vue&type=template&id=2a14e926", () => {
    api.rerender('2a14e926', render)
  })

}

script.__file = "src/components/PushNotifications/PushNotifications.vue"

export default script
import { render } from "./ModalHeader.vue?vue&type=template&id=4e597b18"
import script from "./ModalHeader.ts?vue&type=script&lang=ts"
export * from "./ModalHeader.ts?vue&type=script&lang=ts"

import "./modal-header.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4e597b18"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4e597b18', script)) {
    api.reload('4e597b18', script)
  }
  
  module.hot.accept("./ModalHeader.vue?vue&type=template&id=4e597b18", () => {
    api.rerender('4e597b18', render)
  })

}

script.__file = "src/components/Modal/ModalHeader.vue"

export default script
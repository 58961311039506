import { render } from "./Popup.vue?vue&type=template&id=ebc218ec"
import script from "./Popup.ts?vue&type=script&lang=ts"
export * from "./Popup.ts?vue&type=script&lang=ts"

import "./popup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ebc218ec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ebc218ec', script)) {
    api.reload('ebc218ec', script)
  }
  
  module.hot.accept("./Popup.vue?vue&type=template&id=ebc218ec", () => {
    api.rerender('ebc218ec', render)
  })

}

script.__file = "src/modules/errorsPopup/popup/Popup.vue"

export default script
import { render } from "./ContextMenu.vue?vue&type=template&id=4c85d22d"
import script from "./ContextMenu.ts?vue&type=script&lang=ts"
export * from "./ContextMenu.ts?vue&type=script&lang=ts"

import "./context-menu.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4c85d22d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4c85d22d', script)) {
    api.reload('4c85d22d', script)
  }
  
  module.hot.accept("./ContextMenu.vue?vue&type=template&id=4c85d22d", () => {
    api.rerender('4c85d22d', render)
  })

}

script.__file = "src/components/ContextMenu/ContextMenu.vue"

export default script
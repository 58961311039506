import { render } from "./Toggler.vue?vue&type=template&id=7c2e318d"
import script from "./Toggler.ts?vue&type=script&lang=ts"
export * from "./Toggler.ts?vue&type=script&lang=ts"

import "./toggler.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7c2e318d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7c2e318d', script)) {
    api.reload('7c2e318d', script)
  }
  
  module.hot.accept("./Toggler.vue?vue&type=template&id=7c2e318d", () => {
    api.rerender('7c2e318d', render)
  })

}

script.__file = "src/components/Toggler/Toggler.vue"

export default script
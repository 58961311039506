import { render } from "./Splash.vue?vue&type=template&id=5f226faf"
import script from "./Splash.ts?vue&type=script&lang=ts"
export * from "./Splash.ts?vue&type=script&lang=ts"

import "./splash.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f226faf"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f226faf', script)) {
    api.reload('5f226faf', script)
  }
  
  module.hot.accept("./Splash.vue?vue&type=template&id=5f226faf", () => {
    api.rerender('5f226faf', render)
  })

}

script.__file = "src/components/Splash/Splash.vue"

export default script
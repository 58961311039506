import { render } from "./Cropper.vue?vue&type=template&id=60d9c110"
import script from "./Cropper.ts?vue&type=script&lang=ts"
export * from "./Cropper.ts?vue&type=script&lang=ts"

import "./cropper.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "60d9c110"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('60d9c110', script)) {
    api.reload('60d9c110', script)
  }
  
  module.hot.accept("./Cropper.vue?vue&type=template&id=60d9c110", () => {
    api.rerender('60d9c110', render)
  })

}

script.__file = "src/modules/cropper/Cropper/Cropper.vue"

export default script
import { render } from "./Popup.vue?vue&type=template&id=5f2de6e9"
import script from "./Popup.ts?vue&type=script&lang=ts"
export * from "./Popup.ts?vue&type=script&lang=ts"

import "./popup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f2de6e9"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f2de6e9', script)) {
    api.reload('5f2de6e9', script)
  }
  
  module.hot.accept("./Popup.vue?vue&type=template&id=5f2de6e9", () => {
    api.rerender('5f2de6e9', render)
  })

}

script.__file = "src/components/Popup/Popup.vue"

export default script
import { render } from "./ExpandedAlert.vue?vue&type=template&id=4c9cb597"
import script from "./ExpandedAlert.ts?vue&type=script&lang=ts"
export * from "./ExpandedAlert.ts?vue&type=script&lang=ts"

import "./expandedAlert.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4c9cb597"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4c9cb597', script)) {
    api.reload('4c9cb597', script)
  }
  
  module.hot.accept("./ExpandedAlert.vue?vue&type=template&id=4c9cb597", () => {
    api.rerender('4c9cb597', render)
  })

}

script.__file = "src/components/ExpandedAlert/ExpandedAlert.vue"

export default script
import { render } from "./Avatar.vue?vue&type=template&id=100e3b6f"
import script from "./Avatar.ts?vue&type=script&lang=ts"
export * from "./Avatar.ts?vue&type=script&lang=ts"

import "./avatar.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "100e3b6f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('100e3b6f', script)) {
    api.reload('100e3b6f', script)
  }
  
  module.hot.accept("./Avatar.vue?vue&type=template&id=100e3b6f", () => {
    api.rerender('100e3b6f', render)
  })

}

script.__file = "src/components/Avatar/Avatar.vue"

export default script
import { render } from "./Icon.vue?vue&type=template&id=38d2d0ef"
import script from "./Icon.ts?vue&type=script&lang=ts"
export * from "./Icon.ts?vue&type=script&lang=ts"

import "./icon.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "38d2d0ef"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('38d2d0ef', script)) {
    api.reload('38d2d0ef', script)
  }
  
  module.hot.accept("./Icon.vue?vue&type=template&id=38d2d0ef", () => {
    api.rerender('38d2d0ef', render)
  })

}

script.__file = "src/components/Icon/Icon.vue"

export default script
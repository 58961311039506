import { render } from "./PremiumIcon.vue?vue&type=template&id=42a8b4b1"
import script from "./PremiumIcon.ts?vue&type=script&lang=ts"
export * from "./PremiumIcon.ts?vue&type=script&lang=ts"

import "./premiumIcon.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "42a8b4b1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('42a8b4b1', script)) {
    api.reload('42a8b4b1', script)
  }
  
  module.hot.accept("./PremiumIcon.vue?vue&type=template&id=42a8b4b1", () => {
    api.rerender('42a8b4b1', render)
  })

}

script.__file = "src/components/PremiumIcon/PremiumIcon.vue"

export default script
import { render } from "./NotificationIcon.vue?vue&type=template&id=d89a2a28"
import script from "./NotificationIcon.vue?vue&type=script&lang=ts"
export * from "./NotificationIcon.vue?vue&type=script&lang=ts"

import "./NotificationIcon.vue?vue&type=style&index=0&id=d89a2a28&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d89a2a28"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d89a2a28', script)) {
    api.reload('d89a2a28', script)
  }
  
  module.hot.accept("./NotificationIcon.vue?vue&type=template&id=d89a2a28", () => {
    api.rerender('d89a2a28', render)
  })

}

script.__file = "src/components/AppNotifications/NotificationIcon.vue"

export default script
import { render } from "./Checkbox.vue?vue&type=template&id=20811da2"
import script from "./Checkbox.ts?vue&type=script&lang=ts"
export * from "./Checkbox.ts?vue&type=script&lang=ts"

import "./checkbox.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "20811da2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('20811da2', script)) {
    api.reload('20811da2', script)
  }
  
  module.hot.accept("./Checkbox.vue?vue&type=template&id=20811da2", () => {
    api.rerender('20811da2', render)
  })

}

script.__file = "src/components/Checkbox/Checkbox.vue"

export default script
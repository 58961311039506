import { render } from "./Tag.vue?vue&type=template&id=5e16ef05"
import script from "./Tag.ts?vue&type=script&lang=ts"
export * from "./Tag.ts?vue&type=script&lang=ts"

import "./tag.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5e16ef05"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5e16ef05', script)) {
    api.reload('5e16ef05', script)
  }
  
  module.hot.accept("./Tag.vue?vue&type=template&id=5e16ef05", () => {
    api.rerender('5e16ef05', render)
  })

}

script.__file = "src/components/Tag/Tag.vue"

export default script
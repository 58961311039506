import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { USER_STORE_KEY } from '../constants';
import { UsersAction } from '../enums/UsersAction';

export const useUsersAction = <T = unknown>(key: UsersAction, payload?: T): void => {
  const store = useStore<typeof StoreInjectionKey>();

  store.dispatch(`${USER_STORE_KEY}/${key}`, payload);
};

import { render } from "./NotModeratedPopup.vue?vue&type=template&id=dde23f4a"
import script from "./NotModeratedPopup.ts?vue&type=script&lang=ts"
export * from "./NotModeratedPopup.ts?vue&type=script&lang=ts"

import "./notModeratedPopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "dde23f4a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('dde23f4a', script)) {
    api.reload('dde23f4a', script)
  }
  
  module.hot.accept("./NotModeratedPopup.vue?vue&type=template&id=dde23f4a", () => {
    api.rerender('dde23f4a', render)
  })

}

script.__file = "src/modules/notModerated/popup/NotModeratedPopup.vue"

export default script
import { render } from "./CopyText.vue?vue&type=template&id=a2c13fe2"
import script from "./CopyText.ts?vue&type=script&lang=ts"
export * from "./CopyText.ts?vue&type=script&lang=ts"

import "./copyText.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a2c13fe2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a2c13fe2', script)) {
    api.reload('a2c13fe2', script)
  }
  
  module.hot.accept("./CopyText.vue?vue&type=template&id=a2c13fe2", () => {
    api.rerender('a2c13fe2', render)
  })

}

script.__file = "src/components/CopyText/CopyText.vue"

export default script
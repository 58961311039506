import { render } from "./MajorityPopup.vue?vue&type=template&id=817e6e02"
import script from "./MajorityPopup.ts?vue&type=script&lang=ts"
export * from "./MajorityPopup.ts?vue&type=script&lang=ts"

import "./majorityPopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "817e6e02"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('817e6e02', script)) {
    api.reload('817e6e02', script)
  }
  
  module.hot.accept("./MajorityPopup.vue?vue&type=template&id=817e6e02", () => {
    api.rerender('817e6e02', render)
  })

}

script.__file = "src/components/MajorityPopup/MajorityPopup.vue"

export default script